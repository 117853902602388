import { documentReady } from './documentReady';

class Autocompleter {
  constructor() {
    if (!$("span[data-trigger-instrument-autocompleter='true']").length) return;
    const include_interest_rate_instruments =
      $("span[data-instrument-autocompleter-include-interests='true']").length > 0;
    const allow_all = $("span[data-instrument-autocompleter-allow-all='true']").length > 0;
    $('#instrument_code')
      .autocomplete({
        source(request, response) {
          $.ajax({
            url: '/instruments/find.js',
            dataType: 'json',
            data: {
              term: request['term'],
              market_id: $('#market_id') && $('#market_id').val(),
              include_interest_rate_instruments: include_interest_rate_instruments,
              allow_all,
              records_per_page: 20,
              portfolio_id: $('#search_portfolio_id') && $('#search_portfolio_id').val(),
              consolidated: $('#search_consolidated') && $('#search_consolidated').val(),
            },
            success(res) {
              const data = res.instruments;
              const items = $.map(data, el => ({
                value: el['id'],
                name: el['name'],
                label: el['formatted_string'],
                code: el['code'],
                market_short_code: el['market_short_code'],
              }));
              $('#instrument_id').val('');
              response(items);
            },
          });
        },
        focus() {
          return false;
        },
        select(event, ui) {
          $('#instrument_id').val(ui.item.value);
          // value stores id
          let item_code = ui.item.code;
          const expiredInstrumentPattern = new RegExp(/<?.* ?.*>(.*)<\/?.*>/);
          if (expiredInstrumentPattern.test(item_code)) {
            item_code = expiredInstrumentPattern.exec(item_code)[1];
          }
          $(this).val(item_code);
          $(this).parents('form').submit();
          return false;
        },
        minLength: 1,
      })
      .data('ui-autocomplete')._renderItem = (ul, item) =>
      $('<li></li>').data('item.autocomplete', item).append(`<a>${item.label}</a>`).appendTo(ul);

    $('#instrument_code').focus();
    $('#instrument_code').select();
  }
}

documentReady(function () {
  new Autocompleter(); // share checker
  return $(document).on('overlay-ajax-loaded', '#modal_overlay', () => new Autocompleter());
}); // share checker
